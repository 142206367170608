import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faRobot, faUser, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
// import './ChatApp.css'; // Assuming your CSS is properly set up

const ChatApp = () => {

    useEffect(() => {
        const loadScript = (url) => {
          const script = document.createElement('script');
          script.src = url;
          script.async = false;
          document.body.appendChild(script);
        };
    
        loadScript("/js/configs.js");
        loadScript("/js/clients/restClient.js");
        loadScript("/js/storage/cookie.js");
        loadScript("/js/storage/localStorage.js");
        loadScript("/js/auth/gAuthButtonHandler.js");
        loadScript("/js/auth/gAuth.js");
        loadScript("/js/scripts.js");
        // Add other necessary scripts
    
        // Optional: Cleanup on component unmount
        return () => {
          // Remove scripts if necessary
        };
      }, []);

    const [message, setMessage] = useState('');

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onSendMessageClick();
        }
    };

    const onSendMessageClick = () => {
        onSendMessageClick()
    };

    return (
        <div id="bodyDiv">
            <div className="top-bar">
                <div className="system-info">
                    <i class="fa teacher-icon fa-2x"></i>
                    <span>FIRE Investment Assistant</span>
                </div>
                <div className="user-info">
                    <div id="gAuth-login" className="gPlusSignIn">
                        <span className="icon"/>
                        <span className="buttonText" style={{ paddingRight: '15px' }}>Login</span>
                    </div>
                    <i class="fa student-icon fa-2x"></i>
                </div>
            </div>
            <div id="chatContainer"></div>
            <div id="inputContainer" dangerouslySetInnerHTML={{
                __html: `
                <input type="text" id="messageInput" placeholder="Ask a question..." onkeypress="handleKeyPress(event)">
                <button id="sendButton" class="sendButton" onclick="onSendMessageClick()"><i class="fa fa-paper-plane"></i></button>
                `
            }}/>
            <div id="loader"></div>
        </div>
    );
};

export default ChatApp;
